import React from 'react'

export const Github = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1892" height="1892" viewBox="0 0 1892 1892">
        <defs>
            <clipPath id="clip-Prancheta_5">
            <rect width="1892" height="1892"/>
            </clipPath>
        </defs>
        <g id="Prancheta_5" data-name="Prancheta – 5" clipPath="url(#clip-Prancheta_5)">
            <path id="github" d="M677.964.5C303.389.5,0,298.8,0,666.712,0,961.118,194.237,1210.779,463.558,1298.8c33.9,6.271,46.327-14.35,46.327-32.034,0-15.819-.565-57.74-.847-113.276-188.587,40.169-228.361-89.378-228.361-89.378-30.847-76.893-75.423-97.457-75.423-97.457C143.841,925.356,210,926.2,210,926.2c68.079,4.633,103.841,68.644,103.841,68.644,60.452,101.864,158.7,72.429,197.457,55.424,6.1-43.107,23.559-72.429,42.938-89.1C403.671,944.508,245.423,887.22,245.423,631.967c0-72.712,26.271-132.146,69.774-178.756-7.627-16.836-30.508-84.576,5.932-176.327,0,0,56.779-17.853,186.44,68.3,54.237-14.8,111.864-22.147,169.491-22.486,57.627.339,115.254,7.684,169.491,22.486,128.813-86.158,185.593-68.3,185.593-68.3,36.441,91.751,13.559,159.491,6.78,176.327,43.22,46.61,69.491,106.045,69.491,178.756,0,255.931-158.474,312.259-309.321,328.643,23.729,20,45.763,60.847,45.763,123.276,0,89.152-.847,160.79-.847,182.429,0,17.458,11.864,38.3,46.61,31.638,271.242-87.457,465.309-337.287,465.309-631.241C1355.928,298.8,1052.369.5,677.964.5Z" transform="translate(268 295.5)" fill="#4605ff"/>
        </g>
    </svg>
)