import React from 'react'

export const Dribbble = () => (
    <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="1892" height="1892" viewBox="0 0 1892 1892">
        <defs>
            <clipPath id="clip-Prancheta_7">
            <rect width="1892" height="1892"/>
            </clipPath>
        </defs>
        <g id="Prancheta_7" data-name="Prancheta – 7" clipPath="url(#clip-Prancheta_7)">
            <g id="noun_dribbble_2148036" transform="translate(284 284)">
            <path id="Caminho_90" data-name="Caminho 90" d="M661.76,1322.52C296.832,1322.52,1,1026.687,1,661.76S296.832,1,661.76,1s660.76,295.832,660.76,660.76S1026.687,1322.52,661.76,1322.52Zm0-1233.418C345.489,89.1,89.1,345.489,89.1,661.76s256.388,572.659,572.658,572.659S1234.418,978.03,1234.418,661.76,978.031,89.1,661.76,89.1Z" fill="#4605ff"/>
            <path id="Caminho_91" data-name="Caminho 91" d="M583.8,308.865A1581.861,1581.861,0,0,1,2.33,184.2l35.681-80.613C45.059,106.673,750.31,415.027,1108,8.44l66.076,58.147C1010.209,253.362,789.515,308.865,583.8,308.865Z" transform="translate(57.258 320.297)" fill="#4605ff"/>
            <path id="Caminho_92" data-name="Caminho 92" d="M61.526,407.368,4.26,340.411c25.99-22.466,644.9-542.264,1157.651-218.051l-47.134,74.446C657.091-92.607,67.252,403.4,61.526,407.368Z" transform="translate(140.345 654.812)" fill="#4605ff"/>
            <path id="Caminho_93" data-name="Caminho 93" d="M101.991,1293.305l-68.279-55.5C542.5,613.6,36.355,85,14.33,62.971L77.322,1.3C83.049,7.467,660.553,608.759,101.991,1293.305Z" transform="translate(573.865 12.915)" fill="#4605ff"/>
            </g>
        </g>
    </svg>
)